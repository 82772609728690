// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD9m5P4TN_4SsbXxlLnbqEMeYJGnR7zsog",
  authDomain: "drcapp-prod.firebaseapp.com",
  projectId: "drcapp-prod",
  storageBucket: "drcapp-prod.appspot.com",
  messagingSenderId: "121264946895",
  appId: "1:121264946895:web:5a24604ed1c590c24301ba"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;